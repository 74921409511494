import { StoryblokComponent, storyblokEditable } from "gatsby-source-storyblok"
import React from "react"

import MasonryGridSection from "../../../V2/Layouts/MasonryGridSection"

const StoryblokMasonryGridSection = ({
  blok,
}: Storyblok.BlokProps<Storyblok.MasonrySection>) => {
  return (
    <MasonryGridSection
      id={blok.id}
      headingSize={blok.headingSize}
      alignment={blok.alignment}
      superscriptText={blok.superscriptText}
      overrideSuperscriptColor={blok.overrideSuperscriptColor}
      headingText={blok.headingText}
      overrideHeadingTextColor={blok.overrideHeadingTextColor}
      headingBodyText={blok.headingBodyText}
      overrideHeadingBodyColor={blok.overrideHeadingBodyColor}
      primaryBackgroundColor={blok.primaryBackgroundColor}
      secondaryBackgroundColor={blok.secondaryBackgroundColor}
      // @ts-ignore to have more strict typing compared to Storybloks generic object typing
      {...storyblokEditable(blok)}
    >
      {blok.content.map((content) => (
        <StoryblokComponent key={content._uid} blok={content} />
      ))}
    </MasonryGridSection>
  )
}

export default StoryblokMasonryGridSection
